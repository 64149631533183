import request from '@/utils/request'
// 政采项目总数居获取订单列表
export const getOrderPage = (data) =>
  request({
    url: '/hp/platform/data/v1/screen/gov/order-page',
    method: 'post',
    data: data,
  })
// 获取订单详情
export const getOrderDetail = (orderNo) =>
  request({
    url: '/hp/platform/data/v1/screen/gov/order-detail?orderNo=' + orderNo,
    method: 'get',
  })
// 获取合同统计数据
export const getContractMessage = (data) =>
  request({
    url: '/hp/platform/data/v1/screen/gov/contract/statistics/area',
    method: 'post',
    data,
  })
// 所有合同统计
export const getAreaStatistics = (data) =>
  request({
    url: '/hp/platform/data/v1/screen/gov/contract-statistics',
    method: 'post',
    data,
  })
//   查询所有合同
export const getContypeList = (data) =>
  request({
    url: '/hp/platform/data/v1/screen/gov/contract/area/list',
    method: 'post',
    data,
  })
// 获取区域下的村镇
export const getAreaList = (code) =>
  request({
    url: '/ums/v1/area/sub_list?code=' + code,
    method: 'get',
  })
//根据合同id查询补助信息
export const getAreainfo = (code, data) =>
  request({
    url: `/hp/platform/data/v1/screen/gov/contract/screen/customer_subsidy_info?contractGlobalId=${code}&customerGlobalId=${data}`,
    method: 'get',
  })
