<template>
  <!-- 大屏上侧 -->
  <div class="viewport_cs">
    <div class="orderTop">
      <img src="../../../assets/images/govPage/orderTops.png" alt="" />
    </div>
    <div class="closeImage" @click="back()">
      <img src="../../../assets/images/govPage/order/left_icon1.png" alt="" />
    </div>
    <div class="ds-guanbi" @click="buttoncli">
      <img
        v-if="isFullscreen"
        src="../../../assets/images/govPage/top/guanbi.png"
        alt=""
      />
      <img v-else src="../../../assets/images/govPage/top/dakai.png" alt="" />
    </div>
    <div class="viewport">
      <div class="contractAccount">
        <ul>
          <li>
            <span class="contractAccount-amount">{{
              contractMessage.num
            }}</span>
            <span class="contractAccount-name">合同数量</span>
          </li>
          <li>
            <span class="contractAccount-amount">{{
              contractMessage.totalAmount
            }}</span>
            <span class="contractAccount-name">合同总金额</span>
          </li>
          <li>
            <span class="contractAccount-amount">{{
              contractMessage.remainingAmount
            }}</span>
            <span class="contractAccount-name">合同剩余金额</span>
          </li>
          <li>
            <span class="contractAccount-amount">{{
              contractMessage.amountServed
            }}</span>
            <span class="contractAccount-name">服务金额</span>
          </li>
          <li>
            <span class="contractAccount-amount">{{
              contractMessage.totalNumber
            }}</span>
            <span class="contractAccount-name">合同总人数</span>
          </li>
          <li>
            <span class="contractAccount-amount">{{
              contractMessage.peopleServedNumber
            }}</span>
            <span class="contractAccount-name">服务人数</span>
          </li>
          <li>
            <span class="contractAccount-amount">{{
              contractMessage.personTimeServed
            }}</span>
            <span class="contractAccount-name">服务人次</span>
          </li>
          <li>
            <span class="contractAccount-amount">{{
              contractMessage.progressSpeed
            }}</span>
            <span class="contractAccount-name">总体进度</span>
          </li>
        </ul>
      </div>
      <div class="ds-cen-ht">
        <div class="search">
          <div class="contract_choice">
            <span> 合同选择 </span>
            <el-select
              v-model="selectContract"
              placeholder="请选择合同"
              collapse-tags
              @change="contractChange"
              popper-class="popper-c"
            >
              <el-option
                v-for="item in contractOptions"
                :key="item.globalId"
                :label="item.contractName"
                :value="item.globalId"
              >
              </el-option>
            </el-select>
          </div>
          <div class="contract_choice">
            <span> 合同类型 </span>
            <el-select
              v-model="selectContType"
              placeholder="请选择类型"
              collapse-tags
              @change="contractmisee"
              popper-class="popper-c"
              style="width: 9.2rem"
            >
              <el-option
                v-for="item in tractOptionsess"
                :key="item.contractBusiness"
                :label="item.contractBusinessMark"
                :value="item.contractBusiness"
              >
              </el-option>
            </el-select>
          </div>
          <div class="address">
            <span> 服务地址 </span>
            <div class="city">
              {{ objInfo.province }}
            </div>
            <div class="city">
              {{ objInfo.city }}
            </div>
            <div class="city">
              {{ objInfo.area }}
            </div>
            <div class="street">
              <el-select
                v-model="street"
                collapse-tags
                placeholder="请选择街道/村镇"
                @change="streetChange"
                popper-class="popper-c"
              >
                <el-option
                  v-for="item in areaOptions"
                  :key="item.areaCode"
                  :label="item.areaName"
                  :value="item.areaCode"
                >
                </el-option>
              </el-select>
            </div>
            <el-input
              class="ds-cen-input"
              v-model="e_input"
              placeholder="请输入村社"
            ></el-input>
          </div>

          <div class="service_time">
            <span>服务时间</span>
            <el-date-picker
              v-model="serviceTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </div>
          <div class="button">
            <div class="submit" @click="submit">查询</div>
            <div class="reset" @click="reset">重置</div>
          </div>
        </div>
        <div class="contractAccount-ss">
          <ul>
            <li>
              <span class="contractAccount-amount-s">{{
                mentreaStatistics.totalAmount
              }}</span>
              <span class="contractAccount-name">合同总金额</span>
            </li>
            <li>
              <span class="contractAccount-amount-s">{{
                mentreaStatistics.remainingAmount
              }}</span>
              <span class="contractAccount-name">合同剩余金额</span>
            </li>
            <li>
              <span class="contractAccount-amount-s">{{
                mentreaStatistics.amountServed
              }}</span>
              <span class="contractAccount-name">服务金额</span>
            </li>
            <li>
              <span class="contractAccount-amount-s">{{
                mentreaStatistics.totalNumber
              }}</span>
              <span class="contractAccount-name">合同总人数</span>
            </li>
            <li>
              <span class="contractAccount-amount-s">{{
                mentreaStatistics.peopleServedNumber
              }}</span>
              <span class="contractAccount-name">服务人数</span>
            </li>
            <li>
              <span class="contractAccount-amount-s">{{
                mentreaStatistics.personTimeServed
              }}</span>
              <span class="contractAccount-name">服务人次</span>
            </li>
            <li v-if="this.equipment">
              <span class="contractAccount-amount-s">{{
                mentreaStatistics.bindingNums
              }}</span>
              <span class="contractAccount-name">绑定设备数</span>
            </li>
            <li>
              <span class="contractAccount-amount-s">{{
                mentreaStatistics.progressSpeed
              }}</span>
              <span class="contractAccount-name">总体进度</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="order">
        <div class="orderList">
          <div class="orderTable">
            <el-table
              :data="tableData"
              stripe
              style="width: 100%; margin: auto"
              :header-cell-style="{
                background: '#152452',
                color: '#8EB8E6',
              }"
              @row-click="rowClick"
              :row-style="tableRowStyle"
              :row-class-name="tableRowClassName"
            >
              <el-table-column prop="orderNo" width="220" label="订单编号">
              </el-table-column>
              <el-table-column prop="customerName" label="老人姓名">
              </el-table-column>
              <el-table-column prop="contractName" label="所属合同">
              </el-table-column>
              <el-table-column prop="appointmentTime" label="服务时间">
              </el-table-column>
              <el-table-column prop="payAmount" label="订单金额">
                <template slot-scope="scope">
                  ¥{{ scope.row.payAmount | fixTwice }}
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="page">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="10"
              layout="total, prev, pager, next, jumper"
              :total="total"
              background
            >
            </el-pagination>
          </div>
        </div>
        <div class="orderDetail">
          <div class="orderDetailBox">
            <span class="ds-cen-delest">长者信息</span>
            <div class="ds-cen-delest1">
              <div class="ds-cen-delest2">
                <div class="ds-cen-omg">
                  <span>长者姓名：</span>
                  <span>{{ orderDetailMessage.customerName }}</span>
                </div>
                <div class="ds-cen-omg" style="margin-top: 0.58rem">
                  <span>年龄：</span>
                  <span>{{ num_page }}</span>
                </div>
                <div class="ds-cen-omg" style="margin-top: 0.58rem">
                  <span>身份证号：</span>
                  <span>{{ orderDetailMessage.customerIdCard }}</span>
                </div>
              </div>
              <div class="ds-cen-delesti">
                <div>
                  <span>{{ subsidyAmount }}</span>
                  <span>政府补助金额</span>
                </div>
                <div></div>
                <div>
                  <span>{{ governmentAmount }}</span>
                  <span>补助剩余金额</span>
                </div>
              </div>
            </div>
            <div class="service_detail_title">服务信息</div>
            <div class="service_detail">
              <div class="service_line" style="margin-top: 0.55rem">
                <div class="service_line-special">
                  <div class="service_line_label">服务时长：</div>
                  <div class="ds-cen-tims">
                    {{ serviceItems.serviceDuration }} 分钟
                  </div>
                </div>
                <div class="service_line-special">
                  <div class="service_line_label">服务人员：</div>
                  <div class="ds-cen-tims" style="width: 18rem">
                    {{ orderDetailMessage.staffsArr }}
                  </div>
                </div>
              </div>
              <div class="service_line">
                <div class="service_line_label">服务地址：</div>
                <div class="ds-cen-tims">{{ serviceItems.serviceAddress }}</div>
              </div>
              <div class="service_line">
                <div class="service_line_labels">服务项目：</div>
                <div
                  :class="ac_name == index ? 'ac_name1' : 'ac_name2'"
                  v-for="(item, index) in orderDetailMessage.items"
                  :key="index"
                  @click="handleClick(index, item)"
                >
                  {{ item.serviceName }}
                </div>
              </div>
              <div class="ds-cen-popo">
                <div class="service_line">
                  <div class="service_line_label">开始服务图片：</div>

                  <div
                    class="swiper-slide"
                    v-for="(item, index) in beginImgUrls"
                    :key="index"
                  >
                    <el-image
                      style="width: 4.166rem; height: 4.166rem"
                      :src="item"
                      fit="cover"
                      :preview-src-list="beginImgUrls"
                    >
                    </el-image>
                  </div>
                </div>
                <div class="service_line">
                  <div class="service_line_label">完成服务图片：</div>

                  <div
                    class="swiper-slide"
                    v-for="(item, index) in endImgUrls"
                    :key="index"
                  >
                    <el-image
                      style="width: 4.166rem; height: 4.166rem"
                      :src="item"
                      fit="cover"
                      :preview-src-list="endImgUrls"
                    >
                    </el-image>
                  </div>
                </div>
                <div class="service_line">
                  <div class="service_line_label">评价视频：</div>
                  <div v-if="orderDetailMessage.evaluateVideoUrl">
                    <video
                      :src="orderDetailMessage.evaluateVideoUrl"
                      width="100"
                      height="100"
                      controls
                      v-if="flag"
                    ></video>
                  </div>
                </div>
              </div>
              <div class="service_line">
                <div class="service_line_labels">绑定设备：</div>
                <div class="ds-cen-messge">
                  <div
                    :class="t_pment == index ? 'ac_name1' : 'ac_name2'"
                    v-for="(item, index) in orderDetailMessage.deviceGroup"
                    :key="index"
                    @click="btn_ment(index, item.devices)"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </div>
              <div class="service_line" v-if="orderDetailMessage.deviceGroup">
                <div class="service_line_labels">设备列表：</div>
                <div
                  :class="
                    beginImgUrls.length > 0 ||
                    endImgUrls.length > 0 ||
                    orderDetailMessage.evaluateVideoUrl
                      ? 'ds-cen-list'
                      : 'ds-cen-lik'
                  "
                >
                  <div
                    class="ds-cen-device"
                    v-for="(item, index) in deviceList"
                    :key="index"
                  >
                    <span class="ds-shebei">设备号：{{ item.sn }}</span>
                    <span class="ds-weizhi"
                      >位置：{{ item.deviceAreaMark }}</span
                    >
                    <span class="ds-bangding"
                      >绑定时间：{{ item.bindTime }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import screenfull from 'screenfull'
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import Swiper from 'swiper'
import {
  getOrderPage,
  getOrderDetail,
  getContractMessage,
  getAreaStatistics,
  getContypeList,
  getAreaList,
  getAreainfo,
} from '@/api/govPage/orderDetail.js'
export default {
  components: {
    Swiper,
  },
  // 注册组件
  data() {
    return {
      objInfo: {},
      equipment: false,
      t_pment: 0,
      ac_name: 0,
      e_input: '', //村社
      selectContract: '', //合同选择
      selectContType: '', //合同类型
      street: '', // 街道
      activeName: '',
      //   服务项目选中
      selectDate: '',
      deviceList: [],
      contractOptions: [], //合同下拉框数据
      tractOptionsess: [], //合同类型下拉
      areaOptions: [], // 街道下拉框数据
      serviceTime: [],
      text: ['非常差', '差', '一般', '满意', '非常满意'],
      orderDetailMessage: {
        customerName: '',
        age: '',
        idCard: '',
        subsidyAmount: '',
        remainAmount: '',
        score: null,
        evaluateVideoUrl: '',
        items: [],
      },
      serviceItems: {
        serviceDuration: '',
        staffName: '',
        serviceAddress: '',
        itemNo: '',
        endImgUrls: [],
        beginImgUrls: [],
      },
      endImgUrls: [],
      beginImgUrls: [],
      //   日期只能选择90天范围
      pickerOptions: {
        disabledDate: function (time) {
          if (this.selectDate) {
            let deff = 90 * 24 * 60 * 60 * 1000
            let nowDate = this.selectDate.getTime()
            return (
              time.getTime() > nowDate + deff || time.getTime() < nowDate - deff
            )
          }
          return false
        }.bind(this),
        onPick: (date) => {
          // 如果只选择一个则保存至selectDate 否则selectDate 为空
          if (date.minDate && !date.maxDate) {
            this.selectDate = date.minDate
          } else {
            this.selectDate = null
          }
        },
      },
      flag: false,
      tableData: [],
      getIndex: 0,
      row: {},
      code: '',
      page: 1,
      perPage: 10,
      total: 1,
      contractMessage: {
        num: '',
        totalAmount: '',
        remainingAmount: '',
        amountServed: '',
        additionalAmount: '',
        totalNumber: '',
        personTimeServed: '',
        progressSpeed: '',
      },
      mentreaStatistics: {
        totalAmount: '',
        remainingAmount: '',
        amountServed: '',
        totalNumber: '',
        peopleServedNumber: '',
        personTimeServed: '',
        bindingNums: '',
        progressSpeed: '',
      },
      masterGlobalId: '', //主合同id
      contractBusinessId: '', //-合同类型
      data_time: '',
      governmentAmount: '', //政府补足余额
      subsidyAmount: '', //政府补助金额
      num_page: '',
      //全屏
      isFullscreen: false,
    }
  },
  computed: {
    userInfo() {
      return JSON.parse(sessionStorage.getItem('statistics-gov-userInfo'))
    },
  },
  created() {
    this.choiceTime(-365)
    this.objInfo = this.userInfo
    this.code = this.objInfo.areaCode
    this.getOptions()
    this.getContractMessage()
    let date = new Date()
    this.data_time = date.getFullYear()
  },
  methods: {
    btn_ment(e, i) {
      this.deviceList = i
      this.t_pment = e
    },
    choiceTime(num) {
      const currentDate = new Date()
      const year = currentDate.getFullYear()
      const month = currentDate.getMonth() + 1
      const date = currentDate.getDate()
      this.serviceTime = [
        this.GetDateTime(num),
        year + '-' + month + '-' + date,
      ]
    },
    GetDateTime(dayNum) {
      var dateDay = new Date()
      dateDay.setDate(dateDay.getDate() + dayNum) //获取dayNum天后的日期
      console.log(dateDay)
      var y = dateDay.getFullYear()
      var m =
        dateDay.getMonth() + 1 < 10
          ? '0' + (dateDay.getMonth() + 1)
          : dateDay.getMonth() + 1 //获取当前月份的日期，不足10补0
      var d =
        dateDay.getDate() < 10 ? '0' + dateDay.getDate() : dateDay.getDate() //获取当前几号，不足10补0
      return y + '-' + m + '-' + d
    },

    // 获取下拉选择框里面的数据
    getOptions() {
      const data = {
        districtCode: this.objInfo.areaCode,
      }
      getContypeList(data).then((res) => {
        if (res.code == 0) {
          this.contractOptions = res.data
          this.selectContract = this.contractOptions[0].globalId
          this.tractOptionsess = res.data[0].contracts
          this.selectContType = this.tractOptionsess[0].contractBusiness
          this.getData()
          if (this.contractOptions) {
            this.getAreaStatistics()
          }
        }
      })
      getAreaList(this.objInfo.areaCode).then((res) => {
        if (res.code == 0) {
          this.areaOptions = res.data
        }
      })
    },
    // 获取初始化数据
    getData(data) {
      let list = {
        page: this.page,
        perPage: this.perPage,
        districtCode: this.objInfo.areaCode,
        provinceCode: this.objInfo.provinceCode,
        cityCode: this.objInfo.cityCode,
        masterGlobalId: this.selectContract,
        contractBusiness: this.selectContType,
      }
      list = { ...list, ...data }
      // 分页数据
      getOrderPage(list).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data
          this.total = res.total
          if (this.tableData != null) {
            if (this.tableData.length > 0) {
              this.row = this.tableData[0]
              this.getOrderDetail()
              this.getAreainfo()
            } else {
              this.orderDetailMessage = {
                customerName: '',
                age: '',
                idCard: '',
                subsidyAmount: '',
                remainAmount: '',
                score: null,
                evaluateVideoUrl: '',
                items: [],
              }
              this.serviceItems = {
                serviceDuration: '',
                staffName: '',
                serviceAddress: '',
                itemNo: '',
                endImgUrls: [],
                beginImgUrls: [],
              }
              this.endImgUrls = []
              this.beginImgUrls = []
              this.governmentAmount = '' //政府补足余额
              this.subsidyAmount = '' //政府补助金额
              this.num_page = ''
            }
          }
        }
      })
    },
    getContractMessage(data) {
      let list = {
        districtCode: this.objInfo.areaCode,
      }
      list = { ...list, ...data }
      getContractMessage(list).then((res) => {
        if (res.code == 0) {
          this.contractMessage = res.data
          if (this.userInfo.areaCode == 511703) {
            this.contractMessage.totalAmount = '950000.00'
            this.contractMessage.remainingAmount = (
              950000 - this.contractMessage.amountServed
            ).toFixed(2)
            this.contractMessage.totalNumber = 3167
            this.contractMessage.progressSpeed =
              ((this.contractMessage.amountServed / 950000) * 100).toFixed(2) +
              '%'
          }
        }
      })
    },
    getAreaStatistics(data) {
      let list = {
        districtCode: this.objInfo.areaCode,
        contractBusiness: this.selectContType,
        masterGlobalId: this.selectContract,
      }
      list = { ...list, ...data }

      getAreaStatistics(list).then((res) => {
        if (res.code == 0) {
          this.mentreaStatistics = res.data
          if (this.userInfo.areaCode == 511703) {
            this.mentreaStatistics.totalAmount = '950000.00'
            this.mentreaStatistics.remainingAmount = (
              950000 - this.mentreaStatistics.amountServed
            ).toFixed(2)
            this.mentreaStatistics.totalNumber = 3167
            this.mentreaStatistics.progressSpeed =
              ((this.mentreaStatistics.amountServed / 950000) * 100).toFixed(
                2
              ) + '%'
          }
        }
      })
    },
    GetDateTime(dayNum) {
      var dateDay = new Date()
      dateDay.setDate(dateDay.getDate() + dayNum) //获取dayNum天后的日期
      console.log(dateDay)
      var y = dateDay.getFullYear()
      var m =
        dateDay.getMonth() + 1 < 10
          ? '0' + (dateDay.getMonth() + 1)
          : dateDay.getMonth() + 1 //获取当前月份的日期，不足10补0
      var d =
        dateDay.getDate() < 10 ? '0' + dateDay.getDate() : dateDay.getDate() //获取当前几号，不足10补0
      return y + '-' + m + '-' + d
    },
    getOrderDetail() {
      getOrderDetail(this.row.orderNo).then((res) => {
        if (res.code == 0) {
          console.log(res.data)
          if (res.data.staffs && res.data.staffs.length > 0) {
            let arr = []
            res.data.staffs.forEach((element) => {
              arr.push(element.staffNamePhone)
            })
            res.data['staffsArr'] = arr.join(',')
          }
          this.orderDetailMessage = res.data
          this.num_page =
            this.data_time -
            this.orderDetailMessage.customerIdCard.substring(6, 10)
          if (res.data.deviceGroup != null) {
            this.deviceList = res.data.deviceGroup[0].devices
          }
          this.$nextTick(() => {
            this.flag = true
          })
          if (res.data.items != null && res.data.items.length > 0) {
            this.serviceItems = res.data.items[0]
            this.activeName = res.data.items[0].itemNo
            if (this.serviceItems.beginImgUrls != null) {
              this.beginImgUrls = this.serviceItems.beginImgUrls.split(',')
              this.beginImgUrls.splice(2)
            } else {
              this.beginImgUrls = []
            }
            if (this.serviceItems.endImgUrls != null) {
              this.endImgUrls = this.serviceItems.endImgUrls.split(',')
              this.endImgUrls.splice(2)
            } else {
              this.endImgUrls = []
            }
          } else {
            this.serviceItems = ''
            this.endImgUrls = []
            this.beginImgUrls = []
          }
        }
      })
    },
    reset() {
      this.equipment = false
      this.street = ''
      this.choiceTime(-365)
      this.page = 1
      this.getIndex = 0
      this.getData()
      this.getContractMessage()
      this.getOptions()
      this.getAreaStatistics()
    },
    submit() {
      if (this.street) {
        this.equipment = this.selectContType == 'SMART_SERVICE' ? true : false
        this.page = 1
        const data = {
          masterGlobalId: this.selectContract,
          contractBusiness: this.selectContType,
          streetCode: this.street,
          address: this.e_input,
          appointBeginTime:
            this.serviceTime == null
              ? null
              : this.serviceTime[0]
              ? this.serviceTime[0] + ' 00:00:00'
              : null,
          appointEndTime:
            this.serviceTime == null
              ? null
              : this.serviceTime[1]
              ? this.serviceTime[1] + ' 00:00:00'
              : null,
        }
        let tb = []
        tb.push(this.street)
        const list = {
          masterGlobalId: this.selectContract,
          contractBusiness: this.selectContType,
          streetCode: tb,
          address: this.e_input,
          startAt:
            this.serviceTime == null
              ? null
              : this.serviceTime[0]
              ? this.serviceTime[0] + ' 00:00:00'
              : null,
          endAt:
            this.serviceTime == null
              ? null
              : this.serviceTime[1]
              ? this.serviceTime[1] + ' 00:00:00'
              : null,
        }
        this.getData(data)
        this.getIndex = 0
        this.getAreaStatistics(list)
      } else {
        this.$message.error('查询条件街道、村社不能为空')
      }
    },
    changeCurrent() {
      console.log(this.serviceTime)
      const data = {
        masterGlobalId: this.selectContract,
        contractBusiness: this.selectContType,
        streetCode: this.street,
        appointBeginTime:
          this.serviceTime == null
            ? null
            : this.serviceTime[0]
            ? this.serviceTime[0] + ' 00:00:00'
            : null,
        appointEndTime:
          this.serviceTime == null
            ? null
            : this.serviceTime[1]
            ? this.serviceTime[1] + ' 00:00:00'
            : null,
      }
      let tb = []
      tb.push(this.street)
      const list = {
        masterGlobalId: this.selectContract,
        contractBusiness: this.selectContType,
        streetCode: tb,
        startAt:
          this.serviceTime == null
            ? null
            : this.serviceTime[0]
            ? this.serviceTime[0] + ' 00:00:00'
            : null,
        endAt:
          this.serviceTime == null
            ? null
            : this.serviceTime[1]
            ? this.serviceTime[1] + ' 00:00:00'
            : null,
      }
      console.log(data, list)
      this.getData()
      this.getIndex = 0
    },
    contractChange(e) {
      this.contractOptions.map((item) => {
        if (e == item.globalId) {
          this.tractOptionsess = item.contracts
        }
      })
      this.selectContType = this.tractOptionsess[0].contractBusiness
      this.street = ''
    },
    contractmisee(e) {
      if (e == 'SMART_SERVICE') {
        this.mentreaStatistics.bindingNums = this.mentreaStatistics.bindingNums
          ? this.mentreaStatistics.bindingNums
          : 0
      }
    },
    streetChange() {
      //   this.selectContract = ''
    },
    tableRowClassName({ row, rowIndex }) {
      //把每一行的索引放进row
      row.index = rowIndex
    },
    rowClick(row) {
      this.t_pment = 0
      this.row = row
      this.getOrderDetail()
      this.getAreainfo()
      console.log(this.row)
      this.getIndex = row.index
      console.log(this.getIndex)
    },
    getAreainfo() {
      getAreainfo(this.row.contractGlobalId, this.row.customerGlobalId).then(
        (res) => {
          if (res.code == 0) {
            this.governmentAmount = res.data.governmentAmount
            this.subsidyAmount = res.data.subsidyAmount
          }
        }
      )
    },
    tableRowStyle({ row, rowIndex }) {
      if (this.getIndex === rowIndex) {
        return {
          background: 'rgba(255,158,20,.1)',
          'box-shadow': '0 0 0 0px #ff9e14,inset 0 0 10px #ff9e14',
          border: '1px solid rgba(255,158,20,.1)',
          color: 'rgb(255,158,20)',
        }
      }
    },
    handleCurrentChange(val) {
      this.page = val
      this.changeCurrent()
    },
    // 切换tabs
    handleClick(i, val) {
      this.ac_name = i
      const data = this.orderDetailMessage.items.filter((item) => {
        if (item.itemNo == val.itemNo) {
          return item
        }
      })
      this.serviceItems = data[0]
      if (this.serviceItems.beginImgUrls != null) {
        this.beginImgUrls = this.serviceItems.beginImgUrls.split(',')
      } else {
        this.beginImgUrls = []
      }
      if (this.serviceItems.endImgUrls != null) {
        this.endImgUrls = this.serviceItems.endImgUrls.split(',')
      } else {
        this.endImgUrls = []
      }
      this.$nextTick(() => {
        this.flag = true
      })
    },
    back() {
      this.$router.go(-1)
    },
    // 全屏
    buttoncli() {
      //   判断浏览器是否支持全屏
      if (!screenfull.isEnabled) {
        this.$message({
          message: '你的浏览器不支持全屏',
          type: 'warning',
        })
        return false
      }
      // 将样式改为锁屏
      if (this.isFullscreen == false) {
        //   打开全屏
        this.isFullscreen = true
        screenfull.toggle()
      } else {
        this.isFullscreen = false
        screenfull.toggle()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.viewport_cs {
  background: url(../../../assets/images/govPage/bg2.png) no-repeat 0 0 /
    contain;
  background-size: 100% 100%;
  height: 45rem;
}
.viewport {
  min-height: 780px;
  padding: 0;
  max-width: 1848px;
  min-width: 780px;
  margin: 0 auto;
}
.closeImage {
  position: absolute;
  left: 0.66666667rem;
  top: 0.91666667rem;
  cursor: pointer;
}
.orderTop {
  img {
    width: 100%;
    height: 3.166rem;
  }
}
.orderTop > span {
  margin-top: 0.33333333rem;
  font-size: 1.33rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  background: linear-gradient(0deg, #ffffff 0%, #ffdf49 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ds-cen-ht {
  width: 100%;
  aspect-ratio: 1848/75;
  background: #051137;
  box-shadow: inset 0px 0px 23px 0px rgba(58, 95, 255, 0.5);
  border-radius: 2px;
  opacity: 0.75;
  margin-top: 0.42rem;
  padding-top: 0.833rem;
}
.search {
  //   width: 100%;
  //   aspect-ratio: 1848/75;
  //   background: url(../../imgs/mengzi/search/bg_sousuo.png) no-repeat 0 0 /
  //     contain;
  //   margin-top: 22px;
  font-size: 0.58rem;
  display: flex;
  align-items: center;
  color: #4dc2ff;
  .contract_choice {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.67rem;
    span {
      font-size: 0.58rem;
      font-weight: 400;
      color: #8eb8e6;
    }
    .el-select {
      margin-left: 0.5rem;
    }
    color: #4dc2ff;
    ::v-deep .el-tag.el-tag--info {
      background: transparent;
      border: none;
      color: #4dc2ff;
    }
  }
  .address {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.67rem;
    span {
      margin-right: 0.5rem;
      font-size: 0.58rem;
      font-weight: 400;
      color: #8eb8e6;
    }
    .city {
      width: 2.91rem;
      height: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(7, 18, 85, 0.5);
      box-shadow: inset 0px 0px 8px 0px rgba(0, 180, 255, 0.47);
      border-radius: 1px;
      border: 1px solid #0c87ff;
      font-size: 0.58rem;
      font-weight: 400;
      color: #8eb8e6;
      &:nth-child(3) {
        margin-left: 0.29rem;
        margin-right: 0.29rem;
      }
    }
    .area {
      ::v-deep .el-input__inner {
        background: url(../../../assets/images/govPage/search/input_quxian.png)
          no-repeat 0 0 / contain;
        border: none;
        width: 6.75rem;
        height: 1.92rem !important;
        &::placeholder {
          color: #4dc2ff;
        }
      }
    }
    .street {
      margin-left: 0.33rem;
      ::v-deep .el-input__inner {
        background: rgba(7, 18, 85, 0.5);
        box-shadow: inset 0px 0px 8px 0px rgba(0, 180, 255, 0.47);
        border: 1px solid #0c87ff;
        width: 6.54rem;
        height: 1.6rem !important;
        border-radius: 1px;
        &::placeholder {
          font-size: 0.58rem;
          font-weight: 400;
          color: #8eb8e6;
        }
      }
      ::v-deep .el-tag.el-tag--info {
        background: transparent;
        border: none;
        color: #4dc2ff;
      }
    }
  }
  .service_time {
    margin-left: 0.67rem;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: 0.58rem;
      font-weight: 400;
      color: #8eb8e6;
    }
    ::v-deep .el-date-editor {
      margin-left: 0.5rem;
      background: rgba(7, 18, 85, 0.5);
      box-shadow: inset 0px 0px 8px 0px rgba(0, 180, 255, 0.47);
      border: 1px solid #0c87ff;
      border-radius: 1px;
      width: 13.42rem;
      height: 1.65rem;
      .el-range-input {
        background: transparent;
        &::placeholder {
          color: #4dc2ff;
        }
        font-size: 0.58rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8eb8e6;
      }
      .el-range-separator {
        color: #4dc2ff;
        // line-height: 1.6rem;
      }
    }
  }
  ::v-deep .el-date-editor .el-range__icon {
    line-height: 1.25rem !important;
  }
  .button {
    display: flex;
    justify-content: center;
    align-items: center;

    // height: 100%;
    .submit {
      height: 1.5rem;
      width: 3rem;
      margin-left: 0.5rem;
      background: rgba(255, 133, 41, 0.2);
      box-shadow: inset 0px 0px 12px 0px rgba(255, 184, 41, 0.65);
      border-radius: 1px;
      border: 1px solid #ff8529;
      font-size: 0.67rem;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .reset {
      color: #ffffff;
      font-size: 0.67rem;
      width: 3rem;
      height: 1.5rem;
      margin-left: 0.33rem;
      background: rgba(255, 133, 41, 0.2);
      box-shadow: inset 0px 0px 12px 0px rgba(255, 184, 41, 0.65);
      border: 1px solid #ff8529;
      border-radius: 1px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}
.contractAccount {
  width: 100%;
  height: 4.16rem;
  aspect-ratio: 1848/118;
  background: #051137;
  box-shadow: inset 0px 0px 23px 0px rgba(58, 95, 255, 0.5);
  border-radius: 2px;
  opacity: 0.75;
  border: 1px solid rgba(80, 142, 255, 0.22);
}
.contractAccount-ss {
  width: 100%;
  aspect-ratio: 1848/118;
}
.contractAccount-ss > ul {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.contractAccount-ss > ul > li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
}
.contractAccount > ul {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.contractAccount > ul > li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
}
.contractAccount-amount-s {
  font-size: 1.33rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #8eb8e6;
}
.contractAccount-amount {
  font-size: 1.33rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ff9e14;
}

.contractAccount-name {
  font-size: 0.67rem;
  font-weight: 400;
  color: #8eb8e6;
}
.contract_choice {
  ::v-deep .el-input__inner {
    background: rgba(7, 18, 85, 0.5);
    box-shadow: inset 0px 0px 8px 0px rgba(0, 180, 255, 0.47);
    border-radius: 1px;
    border: 1px solid #0c87ff;
    width: 9.2rem;
    height: 1.92rem !important;
    &::placeholder {
      font-size: 0.58rem;
      font-weight: 400;
      color: #8eb8e6;
    }
  }
}
::v-deep .el-tag {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.order {
  width: 100%;
  aspect-ratio: 1848/728;
  display: flex;
  justify-content: space-between;
  height: 28.91rem;
  padding-bottom: 0.7rem;
  box-sizing: border-box;
  margin-top: 0.41rem;
  .orderList {
    width: 49.2%;
    height: 100%;
    background: url(../../../assets/images/govPage/order/let_liebiao.png) center
      center no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    padding: 0 0.41rem;
    .orderTitle {
      margin: 0 auto;
      margin-top: 0.67rem;
      height: 1.71rem;
      width: 46.08rem;
      background: url(../../../assets/images/govPage/order/bg_title_liebiao.png)
        left center no-repeat;
      display: flex;
      align-items: center;
      font-size: 0.83rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      margin-bottom: 0.625rem;
      span {
        margin-left: 2rem;
      }
    }
    .orderTable {
      width: 100%;
      margin: 0 auto;
      margin-top: 2rem;
      ::v-deep .el-table,
      .el-table tr,
      .el-table td,
      .el-table th {
        background-color: transparent !important;
      }
      overflow: auto;
      flex: 1;
      .el-table {
        ::v-deep td {
          height: 2.2rem;
        }
      }
    }
    .orderTable::-webkit-scrollbar {
      display: none;
    }
    .page {
      ::v-deep .el-input__inner {
        background: transparent;
        border: 1px solid #00d5ff;
        color: #4dc2ff;
      }

      display: flex;
      justify-content: flex-end;
      padding: 0.5rem;
    }
  }
  .orderDetail {
    width: 49.2%;
    height: 100%;
    background: url(../../../assets/images/govPage/order/rig_xinx.png) center
      center no-repeat;
    background-size: 100% 100%;
    padding: 0 0.41rem;
    margin-left: 0.41rem;
    .orderDetailBox {
      margin: 0 auto;
      padding-top: 0.67rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;

      .orderTitle {
        height: 1.71rem;
        width: 100%;
        background: url(../../../assets/images/govPage/order/bg_title_xiangqing.png)
          left center no-repeat;
        display: flex;
        align-items: center;
        font-size: 0.83rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        span {
          margin-left: 2rem;
        }
      }
      .customer {
        width: 100%;
        height: 7.375rem;
        display: flex;
        flex-direction: column;
        .customer_message {
          height: 3.42rem;
          width: 100%;
          border-bottom: 2px solid #0c6fa3;
          display: flex;
          .customer_message_left {
            width: 50%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: flex-start;
            font-size: 0.67rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #0c6fa3;
            padding-left: 1.41rem;
            span {
              color: #4dc2ff;
            }
          }
          .customer_message_right {
            width: 50%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-size: 0.67rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #0c6fa3;
            padding-left: 2rem;
            padding-top: 0.65rem;
            span {
              color: #4dc2ff;
            }
          }
        }
        .government_message {
          flex: 1;
          border-bottom: 2px solid #0c6fa3;
          display: flex;
          .government_message_left {
            height: 100%;
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: flex-start;
            padding-left: 1.41rem;
            div {
              &:nth-child(1) {
                font-size: 0.67rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #0c6fa3;
              }
              &:nth-child(2) {
                font-size: 1.33rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #4dc2ff;
              }
            }
          }
          .government_message_right {
            height: 100%;
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: flex-start;
            padding-left: 2rem;
            div {
              &:nth-child(1) {
                font-size: 0.67rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #0c6fa3;
              }
              &:nth-child(2) {
                font-size: 1.33rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ff9e14;
              }
            }
          }
        }
      }
      .ds-cen-delest {
        margin-top: 1rem;
        font-size: 0.58rem;
        font-weight: 500;
        color: #69b1ff;
      }
      .ds-cen-delest1 {
        margin-top: 0.33rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .ds-cen-delest2 {
        background: rgba(5, 13, 38, 0.31);
        border-radius: 2px;
        border: 1px solid #213060;
        width: 44%;
        padding: 0.75rem 0 0.75rem 1.75rem;
        .ds-cen-omg {
          > span:nth-of-type(1) {
            font-size: 0.58rem;
            font-weight: 400;
            color: #b4c6fe;
            opacity: 0.6;
          }
          > span:nth-of-type(2) {
            font-size: 0.58rem;
            font-weight: 400;
            color: #8eb8e6;
          }
        }
      }
      .ds-cen-popo {
        display: flex;
      }
      .ds-cen-delesti {
        width: 44%;
        padding: 1.08rem 0 1.25rem 1.75rem;
        background: #050f34;
        border-radius: 2px;
        border: 1px solid #213060;
        display: flex;
        align-items: center;
        justify-content: space-around;
        > div:nth-of-type(1) {
          display: flex;
          flex-direction: column;
          align-items: center;
          > span:nth-of-type(1) {
            font-size: 30px;
            font-weight: bold;
            color: #4aff29;
          }
          > span:nth-of-type(2) {
            font-size: 0.58rem;
            font-weight: 400;
            color: #8eb8e6;
          }
        }
        > div:nth-of-type(2) {
          width: 0.0412rem;
          height: 2.833rem;
          background: #213060;
        }
        > div:nth-of-type(3) {
          display: flex;
          flex-direction: column;
          align-items: center;
          > span:nth-of-type(1) {
            font-size: 30px;
            font-weight: bold;
            color: #ff8529;
          }
          > span:nth-of-type(2) {
            font-size: 0.58rem;
            font-weight: 400;
            color: #8eb8e6;
          }
        }
      }
      .service_detail_title {
        margin-top: 0.35rem;
        font-size: 0.58rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #69b1ff;
        margin-bottom: 0.28rem;
      }
      .service_detail {
        width: 100%;
        height: 18.25rem;
        background: rgba(5, 13, 38, 0.31);
        border-radius: 2px;
        border: 1px solid #213060;
        overflow-y: auto;
        .service_line {
          font-size: 0.67rem;
          margin-bottom: 0.55rem;
          color: #4dc2ff;
          display: flex;
          .img_swiper {
            width: 4.166rem;
            height: 4.166rem;
            margin: auto;
            .swiper-container {
              height: 3rem;
              padding-left: 15px;
              padding-right: 20px;
            }
          }

          .service_line-special {
            display: flex;
            &:nth-child(2) {
              margin-left: 6.6rem;
            }
          }
          .service_line_labels {
            color: #0c6fa3;
            width: 4.78rem;
            text-align: right;
            float: left;
            font-size: 0.5833rem;
            font-weight: 400;
            color: #b4c6fe;
            opacity: 0.6;
          }
          .ds-cen-list {
            display: flex;
            width: 85%;
            flex-direction: column;
            font-size: 0.58333rem;
            font-weight: 400;
            color: #8eb8e6;
            height: 7rem;
            overflow-y: auto;
          }
          .ds-cen-lik {
            display: flex;
            width: 85%;
            flex-direction: column;
            font-size: 0.58333rem;
            font-weight: 400;
            color: #8eb8e6;
            height: 10rem;
            overflow-y: auto;
          }
          .ds-cen-list::-webkit-scrollbar {
            display: none;
          }
          .ds-cen-lik::-webkit-scrollbar {
            display: none;
          }
          .ds-cen-device {
            display: flex;
            padding-bottom: 0.5rem;
            border-bottom: 1px solid #1c3269;
          }
          .ds-cen-device:not(:first-child) {
            margin-top: 0.5rem;
          }
          .ds-weizhi {
            display: block;
            width: 100px;
            margin-left: 3.33rem;
          }
          .ds-bangding {
            margin-left: 3.33rem;
          }
          .ds-shebei {
            display: block;
            width: 8.33rem;
          }
          .service_line_label {
            font-size: 0.5833rem;
            font-weight: 400;
            color: #b4c6fe;
            width: 4.78rem;
            text-align: right;
            opacity: 0.6;
          }

          ::v-deep .el-tabs__nav-next {
            line-height: 1rem;
          }
          ::v-deep .el-tabs__nav-prev {
            line-height: 1rem;
          }
          ::v-deep .el-tabs__item {
            font-size: 0.67rem;
            height: 1rem;
            line-height: 0.67rem;
            color: #4dc2ff;
          }

          ::v-deep .el-tabs__active-bar {
            background-color: #ff9e14 !important;
          }
          ::v-deep .is-active {
            color: #ff9e14;
          }
          ::v-deep .el-tabs__nav-wrap::after {
            position: static !important;
          }
          ::v-deep .el-tabs__header {
            margin: 0;
          }
        }
      }
    }
  }
}
::v-deep .el-rate__text {
  color: #fcc601 !important;
}

::v-deep .el-select-dropdown__list {
  background-color: #050f2d !important;
}

::v-deep .el-select-dropdown__item {
  background-color: #050f2d !important;
}
.ds-cen-input {
  width: 6.54rem;
  height: 1.6rem;
  margin-left: 0.33rem;
  ::v-deep .el-input__inner {
    width: 6.54rem;
    height: 1.6rem !important;
    background: rgba(7, 18, 85, 0.5);
    box-shadow: inset 0px 0px 8px 0px rgba(0, 180, 255, 0.47);
    border-radius: 2px;
    border: 1px solid #0c87ff;
    color: #4dc2ff;
    &::placeholder {
      font-size: 0.58rem;
      font-weight: 400;
      color: #8eb8e6;
    }
  }
}
::v-deep .el-input__inner {
  color: #8eb8e6 !important;
}
.ds-cen-tims {
  font-size: 0.5833rem;
  font-weight: 400;
  color: #8eb8e6;
}

.ds-cen-eqment {
  display: flex;
}
.ac_name1 {
  padding: 0 0.3rem 0 0.3rem;
  height: 0.91rem;
  line-height: 0.91rem;
  background: rgba(255, 133, 41, 0.2);
  border-radius: 2px;
  border: 1px solid #ff8529;
  font-size: 0.5833rem;
  font-weight: bold;
  color: #ff8529;
  margin-left: 0.33rem;
}
.ds-cen-messge {
  width: 80%;
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
}
.ac_name2 {
  padding: 0 0.3rem 0 0.3rem;
  height: 0.91rem;
  line-height: 0.91rem;
  border-radius: 2px;
  font-size: 0.5833rem;
  font-weight: 400;
  color: #8eb8e6;
  margin-left: 0.33rem;
  &:hover {
    background: #1b2551;
  }
}
.swiper-slide {
  width: 3.3rem;
  display: flex;
  align-items: center;
  margin-left: 0.3rem;
}
.ds-guanbi {
  position: absolute;
  right: 1rem;
  top: 0.7rem;
}
</style>
<style lang="scss" scoped>
// 时间选择器

.el-date-range-picker {
  background: url(../../../assets/images/govPage/search/bg_rili.png) no-repeat;
  width: 26.4rem;
  height: 15rem;
  border: none;
  background-position: center 0.1rem;
  background-size: 105%;
}
.el-date-range-picker__content.is-left {
  border: none;
}
.el-date-table th {
  border-bottom: solid 1px #0d90ff;
  color: #538fbd;
}
.el-date-table td.next-month,
.el-date-table td.prev-month {
  color: rgba(77, 194, 255, 0.3);
}
.available {
  color: #4dc2ff;
}
.el-date-range-picker__header {
  color: #4dc2ff;
}
.el-date-table td.end-date span,
.el-date-table td.start-date span {
  background-color: #ff9e14;
}

.el-date-table td.in-range div,
.el-date-table td.in-range div:hover,
.el-date-table.is-week-mode .el-date-table__row.current div,
.el-date-table.is-week-mode .el-date-table__row:hover div {
  background: rgba(255, 158, 20, 0.25);
  opacity: 0.6;
  color: #ffffff !important;
}
.el-popper .popper__arrow::after {
  content: '';
  border-width: 0px;
}
.el-popper .popper__arrow {
  border-width: 0px;
}
.el-picker-panel__icon-btn {
  color: #4dc2ff;
}
.el-date-table td.disabled div {
  background-color: #0d90ff;
  opacity: 0.5;
}

/*table边框颜色*/
.el-table--border:after,
.el-table--group:after,
.el-table:before {
  background-color: #0d618d;
}
.el-table--border,
.el-table--group {
  border-color: #0d618d;
}
.el-table td,
.el-table th.is-leaf {
  border-bottom: 1px solid #1c3269;
}

.el-table--border th,
.el-table--border th.gutter:last-of-type {
  border-bottom: 1px solid #0d618d;
}

.el-table--border td,
.el-table--border th {
  border-right: 1px solid #0d618d;
}
.el-table th,
.el-table tr {
  background-color: #070f3a;
  color: #8eb8e6;
}

.warm-row {
  background-color: #ff9e14;
}

.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: transparent;
}
.el-table__row {
  //   background: url(../../imgs/mengzi/order/bg_shuju_selected.png) 0px 0px
  //     no-repeat;
  cursor: pointer;
}
.el-table--striped .el-table__body tr.el-table__row--striped td {
  background-color: transparent;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #312316 !important;
  border: 1px solid #ed7301;
  color: #ed7301;
}
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: transparent;
  border: 1px solid #4dc2ff;
  color: #4dc2ff;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #ff9e14;
}
.el-pagination__total {
  color: #4dc2ff;
}
.el-pagination__jump {
  color: #4dc2ff;
}
.swiper-container {
  --swiper-navigation-size: 0;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background: none;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background: none;
}
.swiper-button-prev {
  position: absolute;
  left: 0px;
}
.swiper-button-next {
  position: absolute;
  right: -18px;
}
.swiper-button-prev,
.swiper-button-next {
  margin-top: -11px;
}

.el-select-dropdown__list {
  background-color: #050f2d;
}

.el-select-dropdown__item {
  background-color: #050f2d;
  color: #4dc2ff;
  border-radius: 3px;
  margin-left: 3px;
  margin-right: 3px;
}

.el-select-dropdown {
  border: 2px solid #0f1f4f;
}
.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background: #0f1f4f;
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  background: #0f1f4f;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected.hover {
  background: #0f1f4f;
}

::v-deep .el-table,
.el-table tr,
.el-table td,
.el-table th {
  background-color: transparent !important;
}

.el-table {
  ::v-deep td {
    height: 3.08333333rem;
    border: none;
  }
}
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background: transparent;
}
::v-deep .el-table td,
::v-deep .el-table th.is-leaf {
  border-bottom: 1px solid #1c3269;
}

::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: transparent;
}
::v-deep .el-table__row {
  cursor: pointer;
}
::v-deep .el-table th,
::v-deep .el-table tr {
  background: transparent;
  color: #8eb8e6;
}
::v-deep .el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: rgba(7, 18, 85, 0.5);
  box-shadow: inset 0px 0px 8px 0px rgba(0, 180, 255, 0.47);
  border: 1px solid #00b4ff;
}
::v-deep .el-pagination.is-background .btn-prev {
  background: rgba(7, 18, 85, 0.5);
  box-shadow: inset 0px 0px 8px 0px rgba(0, 180, 255, 0.47);
  border: 1px solid #00b4ff;
}
::v-deep .el-pagination__total {
  margin-right: 10px;
  font-weight: 400;
  color: #8eb8e6;
}
</style>
